import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static values = { placement: String }

  connect() {
    this.tooltip = new Tooltip(this.element, {
      placement: this.placementValue || "top",
      trigger: "hover focus",
      delay: { show: 1000, hide: 0 }
    })
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.dispose()
    }
  }
}
