import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static values = { url: String, currentState: Boolean }

  connect() {
    this.updateUI(this.currentStateValue);
  }

  toggle(event) {
    event.preventDefault();

    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        this.currentStateValue = data.current_state;
        this.updateUI(data.current_state);
      }
    })
    .catch((error) => console.error("Error:", error))
  }

  updateUI(currentValue) {
    if (currentValue) {
      this.element.querySelector(".icon-visible").setAttribute("style", "display: inline")
      this.element.querySelector(".icon-hidden").setAttribute("style", "display: none")
    } else {
      this.element.querySelector(".icon-hidden").setAttribute("style", "display: inline")
      this.element.querySelector(".icon-visible").setAttribute("style", "display: none")
    }
  }
}
